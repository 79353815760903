<template>
  <header class="bg-black fixed w-full z-10 top-0 h-[60px]">
    <Container>
      <nav class="mx-auto flex items-center justify-between h-[60px]" aria-label="Global">

        <div class="py-2.5 w-[75px] md:w-[180px] lg:hidden">
          <button 
          aria-label="Open navigation" 
          class="cursor-default cursor-pointer relative flex min-w-0 items-center gap-3 rounded-lg p-2 text-left text-base/6 font-medium text-zinc-950 sm:text-sm/5 focus:outline-none focus:ring-0" 
          type="button" 
          data-headlessui-state=""
          @click="showSidebar = true"
          >
            <svg data-slot="icon" viewBox="0 0 20 20" aria-hidden="true" class="w-6 h-6 fill-white">
              <path d="M2 6.75C2 6.33579 2.33579 6 2.75 6H17.25C17.6642 6 18 6.33579 18 6.75C18 7.16421 17.6642 7.5 17.25 7.5H2.75C2.33579 7.5 2 7.16421 2 6.75ZM2 13.25C2 12.8358 2.33579 12.5 2.75 12.5H17.25C17.6642 12.5 18 12.8358 18 13.25C18 13.6642 17.6642 14 17.25 14H2.75C2.33579 14 2 13.6642 2 13.25Z">
              </path>
            </svg>
          </button>
        </div>

        <div class="flex flex-1 justify-center lg:flex-none lg:justify-start">
          <NuxtLink to="/">
            <LogoBig class="w-[100px] sm:w-[110px] md:w-[140px] h-auto" />
          </NuxtLink>
        </div>
        <div class="hidden lg:flex lg:flex-1 lg:justify-end">
          <template v-if="user">
            <NuxtLink v-for="item in navLoggedIn" :key="item.name" :to="item.href" class="leading-none text-white outline-none lg:mr-10">
              {{ item.name }}
            </NuxtLink>
          </template>
          <template v-else>
            <NuxtLink v-for="item in navLoggedOut" :key="item.name" :to="item.href" class="leading-none text-white outline-none lg:mr-10">
              {{ item.name }}
            </NuxtLink>
          </template>
        </div>
        <div class="w-[75px] md:w-[180px] lg:w-auto flex items-center justify-end gap-x-4">
          <template v-if="user">
            <NuxtLink 
            to="/play" 
            class="nba font-bold block leading-none rounded-[20px] border uppercase border-white bg-white text-black px-3 pt-1.5 pb-1.5 md:px-4 text-[13px] md:text-base outline-none">
              Dashboard
            </NuxtLink>
          </template>
          <template v-else>
            <button @click="signin" class="nba font-bold hidden leading-none -mr-3 lg:inline-block block rounded-[20px] border uppercase border-white text-white px-3 pt-1.5 pb-1.5 md:px-4 text-[13px] md:text-base outline-none">
              Log in
            </button>
            <NuxtLink to="/join" class="nba font-bold ml-2 leading-none block rounded-[20px] border uppercase border-white bg-white text-black px-3 pt-1.5 pb-1.5 md:px-4 text-[13px] md:text-base outline-none">
              <span class="md:hidden">Join</span>
              <span class="hidden md:inline-block lg:hidden">Join now</span>
              <span class="hidden lg:inline-block">Start free trial</span>
            </NuxtLink>
          </template>
        </div>
      </nav>
    </Container>

    <TransitionRoot appear :show="showSidebar">
      <Dialog :open="showSidebar" @close="showSidebar = false" class="lg:hidden">

        <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-black/50"></div>
        </TransitionChild>

        <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0 scale-95"
        enter-to="opacity-100 scale-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100 scale-100"
        leave-to="opacity-0 scale-95"
        >
          <DialogPanel class="fixed z-10 inset-y-0 w-full max-w-80 p-2 transition duration-300 ease-in-out">
            <div class="fixed inset-y-0 w-full max-w-80 p-2 left-0 transition duration-300 ease-in-out data-[closed]:-translate-x-full" id="headlessui-dialog-panel-:r4b:" data-headlessui-state="open">
              <div class="flex h-full flex-col rounded-lg bg-zinc-900 shadow-sm ring-1 ring-white/10">
                <div class="flex space-between">
                  <span class="relative">
                    <button aria-label="Close navigation" 
                    type="button"
                    @click="showSidebar = false"
                    class="cursor-default relative flex min-w-0 items-center gap-3 rounded-lg p-2 text-left text-base/6 font-medium text-zinc-950 sm:text-sm/5 focus:outline-none focus:ring-0">
                      <span class="absolute left-1/2 top-1/2 size-[max(100%,2.75rem)] -translate-x-1/2 -translate-y-1/2 [@media(pointer:fine)]:hidden" aria-hidden="true">
                      </span>
                      <svg data-slot="icon" viewBox="0 0 20 20" aria-hidden="true" class="w-6 h-6 fill-white">
                        <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z">
                        </path>
                      </svg>
                    </button>
                  </span>
                </div>
                <WebsiteSidebar />
              </div>
            </div>
          </DialogPanel>
        </TransitionChild>
      </Dialog>
    </TransitionRoot>

    <!-- <NuxtMarquee speed="40" class="bg-white text-black font-xl py-[5px]">
      Banner with news and special offers | Banner with news and special offers
    </NuxtMarquee> -->

  </header>
</template>

<script setup>
import { 
  Dialog, 
  DialogPanel,
  TransitionRoot,
  TransitionChild
} from '@headlessui/vue'

const user = useSupabaseUser()
const router = useRouter()
const showSidebar = ref(false)

const route = useRoute()

watch(
  () => route.path, 
  () => {
    showSidebar.value = false
  }
)

const signin = () => {
  if (!user.value) {
    router.push({ path: "/login" })
  } else {
    router.push({ path: "/play" })
  }
}

const navLoggedIn = [
  // { name: 'Dashboard', href: '/play' },
  { name: 'Collections', href: '/play/collections' },
  { name: 'Explore', href: '/play/explore' },
  { name: 'Favorites', href: '/play/favorites' },

  // { name: 'Blog', href: '/blog' },
  // { name: 'About', href: '/about' }
]

const navLoggedOut = [
  // { name: 'News', href: '/blog' },
  { name: 'Explore', href: '/explore' },
  { name: 'Collections', href: '/collections' },
  // { name: 'About', href: '/about' }
]
</script>